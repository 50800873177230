import React from "react";
import resumeFile from "../documents/resume_assaad_el_najjar.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2016 - 2020",
      title: "Bachelor Degree",
      place: "Kharkiv national University of Air Force",
      desc: "Bachelors degree in aircraft maintenance engineering",
    },
    {
      yearRange: "2014 - 2016",
      title: "Nano-Engineering (Incomplete)",
      place: "MPEI",
      desc: "Began my higher education journey in nano-engineering."
    }
  ];

  const experienceDetails = [
    {
      yearRange: "2023 - Present",
      title: "Senior Cloud Engineer",
      place: "Konplan s.r.o",
      desc: "At Konplan, part of the Krones digital family, I'm on the cloud engineering team. We're turning our focus to modern cloud solutions, helping digitize the beverage industry.",
    },
    {
      yearRange: "2021 - 2023",
      title: "Software Engineer",
      place: "People AI",
      desc: "At People.ai, my role as a Software Engineer revolved around team collaboration to enhance our multi-tenant system. I spearheaded updates to key parts of our app and led several projects, always guided by data to drive improvement. My focus was on keeping projects on track and communicating effectively with upper management."
    },
    {
      yearRange: "2019 - 2021",
      title: "Software Engineer",
      place: "Gooten",
      desc: "At Gooten, I played a key role in accelerating our image processing capabilities using AWS Lambda, resulting in significant improvements. I was responsible for establishing CI/CD pipelines, ensuring seamless deployments. My role also included developing useful tools to enhance our Jenkins setup and creating custom solutions for major clients transitioning to Gooten.",
    },
  ];

  const skills = [
    {
      name: "Client-Centric Approach",
      percent: 79,
    },
    {
      name: "System Architecture & Design",
      percent: 80,
    },
    {
      name: "Team Collaboration & Communication",
      percent: 84,
    },
    {
      name: "Cloud Solutions (AWS & Azure)",
      percent: 85,
    },
    {
      name: "Leadership & Initiative",
      percent: 91,
    },
    {
      name: "Coding (Python, Scala, Typescript, etc..)",
      percent: 99,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-6">
            <h2
                className={
                    "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                    <div
                        key={index}
                        className={
                            "bg-white  rounded p-4 mb-4 " +
                            (darkTheme ? "bg-dark" : "bg-white border")
                        }
                    >
                      <p className="badge bg-primary text-2 fw-400">
                        {value.yearRange}
                      </p>
                      <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                        {value.title}
                      </h3>
                      <p className={darkTheme ? "text-primary" : "text-danger"}>
                        {value.place}
                      </p>
                      <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                        {value.desc}
                      </p>
                    </div>
                ))}
          </div>
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-primary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
